import React, { FC } from 'react';
import { Claim } from 'utils/Constants/ClaimsConstants';
import { Role } from 'utils/Constants/RoleConstants';
import { ClaimModel } from 'api';

export interface Session {
    id: string;
    firstName: string;
    insertion?: string;
    lastName: string;
    fullName: string;
    employeeInitials?: string;
    profilePhotoCdnHash: string;
    username: string;
    role?: Role;
    clientId?: number;
    clientName?: string;
    slug?: string;
    isWvb?: boolean;
    secLevel: number;
    userPermissions: { AuthorizedForAllClientProjects: boolean, AuthorizedForAllClientProjectDocuments: boolean, hasPermissionForInspectionTypeFilter: boolean, hasPermissionForFacilityExport: boolean };
    userProjectPermissions: { clientId: number, projectId: number, hasPermissionForDocuments: boolean }[];
    userObjectPermissions: { clientId: number, objectId: number, hasPermissionForDocuments: boolean }[];
    userClientProjectPermissions: { clientId: number, projectId: number, hasPermissionForDocuments: boolean }[];
    userClientObjectPermissions: { clientId: number, fromClientId: number, objectId: number, hasPermissionForAllProjects: boolean, hasPermissionForDocuments: boolean }[];
    userClientToClientPermissions: { clientId: number, toClientId: number, hasPermissionForDocuments: boolean }[];
}

export interface SessionContextValue {
    session?: Session;
    setSession: (claims: ClaimModel[]) => Session;
    clearSession: () => void;
    hasRole: (role: Role) => boolean;
    hasPermissionForDocuments: (projectId: number, objectId: number, projectClientId: number) => boolean;
    hasPermissionForInspectionTypeFilter: () => boolean;
    hasPermissionForFacilityExport: () => boolean;
    hasPermissionToPlanAssignments: () => boolean;
    hasPermissionToDecoupleProjects: () => boolean;
    hasPermissionToEditNonMutableEntities: () => boolean;
    hasPermissionToEditProjectClientAndObject: () => boolean;
    hasPermissionToManageEmployees: () => boolean;
    hasPermissionToEditProjectState: () => boolean;
    hasPermissionToEditProjectInvoiceState: () => boolean;
}

const emptySession: Session = {
    id: '',
    firstName: '',
    insertion: '',
    lastName: '',
    fullName: '',
    employeeInitials: '',
    profilePhotoCdnHash: '',
    username: '',
    userPermissions: { AuthorizedForAllClientProjectDocuments: false, AuthorizedForAllClientProjects: false, hasPermissionForInspectionTypeFilter: false, hasPermissionForFacilityExport: false },
    userProjectPermissions: [],
    userObjectPermissions: [],
    userClientProjectPermissions: [],
    userClientObjectPermissions: [],
    userClientToClientPermissions: [],
    isWvb: false,
    secLevel: -1
};

function convertToSession(claims: ClaimModel[]) {
    function claimValues(claimName: Claim) {
        const claim = claims.find((c) => c.type === claimName);
        return claim
            ? claim.values
            : undefined;
    }

    function claimFirstValue(claimName: Claim) {
        const values = claimValues(claimName);
        return values
            ? values[0]
            : undefined;
    }

    function convertToBoolean(value: string) {
        try {
            return JSON.parse(value.toLowerCase());
        } catch (e) {
            return undefined;
        }
    }

    const id = claimFirstValue(Claim.UserId) ?? '';
    const firstName = claimFirstValue(Claim.FirstName) ?? '';
    const insertion = claimFirstValue(Claim.Insertion) ?? '';
    const lastName = claimFirstValue(Claim.LastName) ?? '';
    const employeeInitials = claimFirstValue(Claim.EmployeeInitials) ?? '';
    const fullName = `${firstName} ${insertion} ${lastName}`.replace('  ', ' ');
    const profilePhotoCdnHash = claimFirstValue(Claim.ProfilePhotoCdnHash) ?? '';
    const username = claimFirstValue(Claim.UserName) ?? '';
    const roles = claimValues(Claim.Roles);
    const role = roles != null && roles.length > 0
        ? roles[0] as Role
        : undefined;
    const clientIdValue = claimFirstValue(Claim.ClientId);
    const clientId = clientIdValue !== undefined ? +clientIdValue : undefined;
    const clientName = claimFirstValue(Claim.ClientName);
    const slug = claimFirstValue(Claim.slug);
    const claimIsWvbValue = claimFirstValue(Claim.IsWvb);
    const isWvb = claimIsWvbValue != null ? convertToBoolean(claimIsWvbValue) : false;
    const secLevelValue = claimFirstValue(Claim.SecLevel);
    const secLevel = secLevelValue != null ? +secLevelValue : -1;

    const claimUserPermissions = claimValues(Claim.CustomerUserPermissions) ?? [];
    const claimUserPermissionsParts = claimUserPermissions[0]?.split(':') ?? undefined;
    const userPermissions = claimUserPermissionsParts
        ? {
            AuthorizedForAllClientProjectDocuments: convertToBoolean(claimUserPermissionsParts[1]),
            AuthorizedForAllClientProjects: convertToBoolean(claimUserPermissionsParts[0]),
            hasPermissionForInspectionTypeFilter: convertToBoolean(claimUserPermissionsParts[2]),
            hasPermissionForFacilityExport: convertToBoolean(claimUserPermissionsParts[3])
        }
        : {
            AuthorizedForAllClientProjectDocuments: false,
            AuthorizedForAllClientProjects: false,
            hasPermissionForInspectionTypeFilter: false,
            hasPermissionForFacilityExport: false
        };

    const claimsUserProjectPermissions = claimValues(Claim.UserProjectPermission) ?? [];
    const userProjectPermissions = claimsUserProjectPermissions.map((c: any) => {
        const parts = c.split(':');
        return { clientId: +parts[0], projectId: +parts[1], hasPermissionForDocuments: convertToBoolean(parts[2]) };
    });

    const claimsUserObjectPermissions = claimValues(Claim.UserObjectPermission) ?? [];
    const userObjectPermissions = claimsUserObjectPermissions.map((c: any) => {
        const parts = c.split(':');
        return { clientId: +parts[0], objectId: +parts[1], hasPermissionForDocuments: convertToBoolean(parts[2]) };
    });

    const claimsClientProjectPermissions = claimValues(Claim.ClientProjectPermission) ?? [];
    const userClientProjectPermissions = claimsClientProjectPermissions.map((c: any) => {
        const parts = c.split(':');
        return { clientId: +parts[0], projectId: +parts[1], hasPermissionForDocuments: convertToBoolean(parts[2]) };
    });

    const claimsClientObjectPermissions = claimValues(Claim.ClientObjectPermission) ?? [];
    const userClientObjectPermissions = claimsClientObjectPermissions.map((c: any) => {
        const parts = c.split(':');
        return { clientId: +parts[0], fromClientId: +parts[0], objectId: +parts[2], hasPermissionForAllProjects: convertToBoolean(parts[3]), hasPermissionForDocuments: convertToBoolean(parts[4]) };
    });

    const claimsClientToClientPermissions = claimValues(Claim.ClientToClientPermission) ?? [];
    const userClientToClientPermissions = claimsClientToClientPermissions.map((c: any) => {
        const parts = c.split(':');
        return { clientId: +parts[0], toClientId: +parts[1], hasPermissionForDocuments: convertToBoolean(parts[2]) };
    });

    const newSession: Session = {
        id: Array.isArray(id) ? id[0] : id,
        firstName,
        insertion,
        lastName,
        fullName,
        employeeInitials,
        profilePhotoCdnHash: Array.isArray(profilePhotoCdnHash) ? profilePhotoCdnHash[0] : profilePhotoCdnHash,
        username,
        role,
        clientId: Array.isArray(clientId) ? clientId[0] : clientId,
        clientName: Array.isArray(clientName) ? clientName[0] : clientName,
        slug: Array.isArray(slug) ? slug[0] : slug,
        isWvb,
        secLevel,
        userPermissions,
        userProjectPermissions,
        userObjectPermissions,
        userClientProjectPermissions,
        userClientObjectPermissions,
        userClientToClientPermissions
    };

    return newSession;
}

const SessionContext = React.createContext<SessionContextValue>({
    session: undefined,
    setSession: (claims: ClaimModel[]) => {
        return convertToSession(claims);
    },
    // eslint:disable: no-empty
    clearSession: () => {
        // empty
    },
    // eslint:enable: no-empty
    hasRole: (role: Role) => false,
    hasPermissionForDocuments: (projectId: number, objectId: number, projectClientId: number) => false,
    hasPermissionForInspectionTypeFilter: () => false,
    hasPermissionForFacilityExport: () => false,
    hasPermissionToPlanAssignments: () => false,
    hasPermissionToDecoupleProjects: () => false,
    hasPermissionToEditNonMutableEntities: () => false,
    hasPermissionToEditProjectClientAndObject: () => false,
    hasPermissionToManageEmployees: () => false,
    hasPermissionToEditProjectState: () => false,
    hasPermissionToEditProjectInvoiceState: () => false
});

interface SessionProviderProps {

}

export const SessionProvider: FC<SessionProviderProps> = (props) => {
    const [session, setSession] = React.useState<Session>();

    const updateSession = React.useCallback((claims: ClaimModel[]) => {
        const newSession = convertToSession(claims);
        setSession(newSession);

        return newSession;
    }, [setSession]);

    const clearSession = React.useCallback(() => {
        setSession(emptySession);
    }, [setSession]);

    const hasRole = React.useCallback((role: Role) => {
        return session != null
            ? session.role === role
            : false;
    }, [session]);

    const hasPermissionForDocuments = React.useCallback((projectId: number, objectId: number, projectClientId: number) => {
        if (!session) {
            return false;
        }

        if (session.role === Role.Admin) {
            return true;
        }

        const clientId = session.clientId!;

        const userDocumentsPermission = session.userPermissions.AuthorizedForAllClientProjectDocuments;
        const userProjectsPermission = session.userPermissions.AuthorizedForAllClientProjects;

        const userProjectPermission = hasUserProjectPermission(clientId, projectId);
        const userObjectPermission = hasUserObjectPermission(clientId, objectId);
        const userProjectDocumentsPermission = hasUserProjectDocumentsPermission(clientId, projectId);
        const userObjectDocumentsPermission = hasUserObjectDocumentsPermission(clientId, objectId);

        // if user has rights for all projects & documents of its client AND has no permission for this project -> return true.
        // if user doesn't have rights for all projects but has permission for this project AND permission for the documents of this project -> return true.
        if (projectClientId === clientId && ((userProjectsPermission && userDocumentsPermission && !userProjectPermission && !userObjectPermission)
            || (!userProjectsPermission && ((userProjectPermission && userProjectDocumentsPermission) || (userObjectPermission && userObjectDocumentsPermission))))) {
            return true;
        }

        // if user doesnt have the user specific permissions, it needs the permissions for all clients projects and documents
        // to be able to see the projects and documents of other clients. So if either one is false -> return false.
        if (!userProjectsPermission || !userDocumentsPermission) {
            return false;
        }

        const userClientProjectDocumentsPermission = hasUserClientProjectDocumentsPermission(clientId, projectId);
        const userClientObjectDocumentsPermission = hasUserClientObjectDocumentsPermission(clientId, objectId);
        const userClientToClientDocumentsPermission = hasUserClientToClientDocumentsPermission(clientId, projectClientId);

        const result = userClientProjectDocumentsPermission ?? userClientObjectDocumentsPermission ?? userClientToClientDocumentsPermission ?? false;

        return result;

    }, [session]);

    const hasUserProjectPermission = React.useCallback((clientId: number, projectId: number): boolean => {
        if (!session) {
            return false;
        }

        const permission = session.userProjectPermissions.some((p) => p.clientId === clientId && p.projectId === projectId);

        return permission;
    }, [session]);

    const hasUserObjectPermission = React.useCallback((clientId: number, objectId: number): boolean => {
        if (!session) {
            return false;
        }

        const permission = session.userObjectPermissions.some((p) => p.clientId === clientId && p.objectId === objectId);

        return permission;
    }, [session]);

    const hasUserProjectDocumentsPermission = React.useCallback((clientId: number, projectId: number): boolean | undefined => {
        if (!session) {
            return false;
        }

        const permission = session.userProjectPermissions.find((p) => p.clientId === clientId && p.projectId === projectId);

        return permission?.hasPermissionForDocuments ?? undefined;
    }, [session]);

    const hasUserObjectDocumentsPermission = React.useCallback((clientId: number, objectId: number): boolean | undefined => {
        if (!session) {
            return false;
        }

        const permission = session.userObjectPermissions.find((p) => p.clientId === clientId && p.objectId === objectId);

        return permission?.hasPermissionForDocuments ?? undefined;
    }, [session]);

    const hasUserClientProjectDocumentsPermission = React.useCallback((clientId: number, projectId: number): boolean | undefined => {
        if (!session) {
            return false;
        }

        const permission = session.userClientProjectPermissions.find((p) => p.clientId === clientId && p.projectId === projectId);

        return permission?.hasPermissionForDocuments ?? undefined;
    }, [session]);

    const hasUserClientObjectDocumentsPermission = React.useCallback((clientId: number, objectId: number): boolean | undefined => {
        if (!session) {
            return false;
        }

        const permission = session.userClientObjectPermissions.find((p) => p.clientId === clientId && p.objectId === objectId);

        if (!permission) {
            return undefined;
        }

        return permission.hasPermissionForAllProjects && permission.hasPermissionForDocuments;
    }, [session]);

    const hasUserClientToClientDocumentsPermission = React.useCallback((clientId: number, toClientId: number): boolean | undefined => {
        if (!session) {
            return false;
        }

        const permission = session.userClientToClientPermissions.find((p) => p.clientId === clientId && p.toClientId === toClientId);

        return permission?.hasPermissionForDocuments ?? undefined;
    }, [session]);

    const hasPermissionForInspectionTypeFilter = React.useCallback(() => {
        if (!session) {
            return false;
        }

        return session.userPermissions.hasPermissionForInspectionTypeFilter || session.role === Role.Admin;
    }, [session]);

    const hasPermissionForFacilityExport = React.useCallback(() => {
        if (!session) {
            return false;
        }

        return session.userPermissions.hasPermissionForFacilityExport || session.role === Role.Admin;
    }, [session]);

    const hasPermissionToPlanAssignments = React.useCallback(() => {
        if (!session) {
            return false;
        }

        return session.isWvb || session.secLevel === 9;
    }, [session]);

    const hasPermissionToDecoupleProjects = React.useCallback(() => {
        if (!session) {
            return false;
        }

        return session.isWvb || session.secLevel === 9;
    }, [session]);

    const hasPermissionToEditNonMutableEntities = React.useCallback(() => {
        if (!session) {
            return false;
        }

        return session.isWvb || session.secLevel === 9;
    }, [session]);

    const hasPermissionToEditProjectClientAndObject = React.useCallback(() => {
        if (!session) {
            return false;
        }

        return session.isWvb || session.secLevel === 9;
    }, [session]);

    const hasPermissionToManageEmployees = React.useCallback(() => {
        if (!session) {
            return false;
        }

        return session.secLevel === 9;
    }, [session]);

    const hasPermissionToEditProjectState = React.useCallback(() => {
        if (!session) {
            return false;
        }

        return session.isWvb || session.secLevel === 9;
    }, [session]);

    const hasPermissionToEditProjectInvoiceState = React.useCallback(() => {
        if (!session) {
            return false;
        }

        return session.isWvb || session.secLevel === 9;
    }, [session]);

    const context: SessionContextValue = React.useMemo(() => ({
        session,
        setSession: updateSession,
        clearSession,
        hasRole,
        hasPermissionForDocuments,
        hasPermissionForInspectionTypeFilter,
        hasPermissionForFacilityExport,
        hasPermissionToPlanAssignments,
        hasPermissionToDecoupleProjects,
        hasPermissionToEditNonMutableEntities,
        hasPermissionToEditProjectClientAndObject,
        hasPermissionToManageEmployees,
        hasPermissionToEditProjectState,
        hasPermissionToEditProjectInvoiceState
    }), [session, updateSession, clearSession, hasRole, hasPermissionForDocuments,
        hasPermissionForInspectionTypeFilter, hasPermissionForFacilityExport,
        hasPermissionToPlanAssignments, hasPermissionToDecoupleProjects,
        hasPermissionToEditNonMutableEntities, hasPermissionToEditProjectClientAndObject,
        hasPermissionToManageEmployees, hasPermissionToEditProjectState, hasPermissionToEditProjectInvoiceState
    ]);

    return (
        <SessionContext.Provider value={context} {...props} />
    );
};

export const useSession = () => {
    const context = React.useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }

    return context;
};
